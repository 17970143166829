
a {
  text-decoration: none;
}


[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block; /* For Firefox */
  color: #b4b8be;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
