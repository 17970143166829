body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: white !important;
}

@font-face {
  font-family: 'GTAmerica';
  src: url(./font/GT-America-Standard-Medium.otf) format('opentype');
  font-weight: medium;
}


@font-face {
  font-family: 'GTAmerica';
  src: url(./font/GT-America-Extended-Regular.otf) format('opentype');
  font-stretch: expanded;
}

@font-face {
  font-family: 'GTAmerica';
  src: url(./font/GT-America-Expanded-Regular.otf) format('opentype');
  font-stretch: extra-expanded;
}

@font-face {
  font-family: 'GTAmerica';
  src: url(./font/GT-America-Standard-Regular.otf) format('opentype');
}

